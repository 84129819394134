/* CARTS */
.home-cart {
  border-radius: 30px !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  padding: 25px;
  min-height: 180px;
  height: 100%;
}

.home-carts .home-cart.css-bhp9pd-MuiPaper-root-MuiCard-root {
  border-radius: 10px !important;
}

.home-cart-col-space {
  margin-bottom: 2rem;
}

.home-cart-headrer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.home-cart-headrer img {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(69, 117, 215, 0.18);
  border-radius: 6.5625px;
  padding: 12px;
}

.home-cart-headrer h3 {
  font-size: 22px;
  padding-left: 22px;
  width: calc(100% - 50px);
}

.home-cart-footer h3 {
  font-weight: 600;
}

.home-cart-footer {
  margin-top: 16px;
}

.home-cart .MuiCardContent-root {
  padding: 0 !important;
}

.totalCount {
  margin-top: 23px;
}

.totalPerc {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.7rem;
  display: inline-block;
}

.totalPerc.totalPerc-up {
  color: #608b70;
}

.totalPerc.totalPerc-down {
  color: #a8605a;
}

.total.countMap {
  display: flex; 
  justify-content: space-between;
}

.totalMap {
  width: 160px;
  margin-top: -40px;
}

.TrafficChannelCardHeader_header {
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(138, 143, 160, 0.5);
  margin-bottom: 15px;
}

.TrafficChannelCardHeader_header .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.TrafficChannelCardHeader_header h5 {
  margin: 0;
  font-size: 18px;
}

.performance-boxes {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
}

.performance-box {
  width: 120px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px 16px;
  gap: 4px;
}

.performance-box span,
.performance-box strong {
  display: inline-block;
  width: 100%;
  text-align: center;
}

.performance-box span {
  font-size: 12px;
  line-height: 1.2;
}

.performance-box strong {
  font-size: 14px;
  font-weight: 600;
}

.home-cart-chart {
  margin-top: 30px;
}

.home-cart-table {
  padding-top: 20px;
}

.home-cart-table .MuiPaper-root {
  margin-top: 44px;
  box-shadow: none;
}

.home-cart-table .MuiPaper-root th {
  font-weight: 600;
}

.home-cart-table .MuiPaper-root th,
.home-cart-table .MuiPaper-root td {
  font-size: 16px;
}

.home-cart-table .MuiPaper-root td {
  font-size: 20px;
  font-weight: 700;
}

#chart {
  width: 100%;
  height: 300px;
}


.MuiCardMedia-img {
  background: none !important;
  padding: 0 !important;
}

/* Responsive adjustments */
@media screen and (max-width: 1400px) {
  .performance-box {
    width: 110px;
    padding: 6px 10px;
  }
}

@media screen and (max-width: 1280px) {  
  .performance-box {
    width: 100px;
  }

  .performance-box span {
    font-size: 11px;
  }

  .performance-box strong {
    font-size: 13px;
  }

  .home-cart-headrer img {
    height: 40px;
    width: 40px;
}

.home-cart-headrer h3 {
    font-size: 16px;
    padding-left: 16px;
}
  
.home-cart-table-header h4 {
    font-size: 15px;
}

.TrafficChannelCardHeader_header h5 {
    font-size: 14px;
}


.home-cart-table .MuiPaper-root th {
    font-size: 14px;
    padding: 10px;
} 

.home-cart-table .MuiPaper-root td {font-size: 18px; padding: 10px;}

.home-cart-table .MuiPaper-root {
  margin-top: 20px;
}

.home-cart-table {padding-top: 0;}

}

 

@media screen and (max-width: 992px) {
  .col-3,
  .col-9 {
    width: 100%;
  }
 
  .totalMap {
    width: 120px;
    margin-top: -50px;
  }
 
  .performance-boxes {
    justify-content: flex-start;
    margin-top: 15px;
    width: 100%;
  }

  .performance-box {
    width: calc(33.33% - 10px);
  }
}

@media screen and (max-width: 768px) {
  .home-cart {
    margin-bottom: 0;
    min-height: auto;
    height: auto;
  }
 

  #chart {
    height: 250px;
  }
  .header_planing_item {
    box-shadow: 0 0 !important; 
    background-color: transparent !important;
    max-width: 100px;
    width: 100px;
}
.total.countMap {
  flex-wrap: wrap;
}
}

@media screen and (max-width: 576px) {
  .home-cart-headrer img {
    height: 30px;
    width: 30px;
  }
  
  .home-cart-table .MuiPaper-root th { font-size: 12px; padding-left: 0; padding-right: 0;  }  
  .home-cart-table .MuiPaper-root td {font-size: 14px;  padding-left: 0; padding-right: 0;}

  #chart {
    height: 200px;
  }
}

@media screen and (max-width: 380px) {
  .home-cart-headrer h3 {
    font-size: 14px;
  }
  .totalMap {
    width: 100px; }
  .home-cart-footer h2 {font-size: 12px;}
  .totalMap { 
    margin-top: -30px;
  }
}

