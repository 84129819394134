@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  box-sizing: border-box;
}
html {
  font-size: 14px;
}
img {
  max-width: 100%;
}
body {
  font-size: 1rem;
  font-family: inter;
  font-weight: 400;
  color: #000000;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  line-height: 1.2;
  margin: 0;
  font-weight: 700;
}
h1,
.h1 {
  font-size: calc(1.375rem + 1.5vw);
}
h2,
.h2 {
  font-size: calc(1.325rem + 0.9vw);
}

h3,
.h3 {
  font-size: calc(1.3rem + 0.6vw);
}
h4,
.h4 {
  font-size: calc(1.275rem + 0.3vw);
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

.d-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-space-end {
  justify-content: end;
}

.align-center {
  align-items: center;
}
/* .nowrap {text-wrap: nowrap} */

.h-full {
  height: 100%;
}

/* COMMON BUTTON */
.css-jh47zj-MuiButtonBase-root-MuiButton-root {
  background-color: #242641 !important;
}
.css-jh47zj-MuiButtonBase-root-MuiButton-root:hover {
  background-color: #484b75 !important;
}
/*END COMMON BUTTON */

/* LAYOUT */
.page-layout {
  display: flex;
  width: 100%;
  padding: 0;
  margin: 0;
  position: relative;
}

.page-layout-left {
  width: 95px;
  padding-right: 10px;
  padding-left: 10px;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
}

.page-layout-right {
  width: 100%;
  padding-left: 95px;
  padding-right: 20px;
}

.page-content {
  border-radius: 3rem;
  overflow: hidden;
  z-index: 99;
  position: relative;
  padding: 35px 35px 35px 35px;
  margin-bottom: 35px;
}
/* END LAYOUT */

@media screen and (max-width: 767px) {
  .header_right_bar .DownloadlastMontht {
    order: -1;
  }
  .page-layout-left {
    z-index: 9999;
    left: -200px;
    transition: 300ms left;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .bt-menu-open .page-layout-left {
    left: 0px;
  }
  .model-filter-labels label {
    padding: 6px 10px;
    font-size: 10px;
  }

  .MuiTypography-h4 {
    font-size: 20px !important;
  }
  .css-4c94nt {
    margin-bottom: 10px;
  }

  /* TABLE RESPONSIVE */
  .css-51find-MuiFormControl-root-MuiTextField-root {
    width: 100%;
  }

  .css-1j8n4q3 {
    flex-wrap: wrap;
    gap: 20px;
  }

  .css-4c94nt .MuiTypography-h4 {
    width: 100%;
  }

  .css-4c94nt {
    flex-wrap: wrap;
  }

  .css-ciakuz-MuiTypography-root {
    font-size: 11px !important;
  }
  /*  END TABLE RESPONSIVE */
}

@media screen and (max-width: 767px) {
  .page-content {
    border-radius: 1rem;
    overflow: hidden;
    z-index: 99;
    position: relative;
    padding: 1px;
  }
}
/* Grid */
.row {
  margin-left: -14px;
  margin-right: -14px;
  display: flex;
  flex-wrap: wrap;
}

[class*="col-"] {
  padding-left: 14px;
  padding-right: 14px;
}

/* GRID */
.col-1 {
  flex: 0 0 8.33%;
  max-width: 8.33%;
}
.col-2 {
  flex: 0 0 16.66%;
  max-width: 16.66%;
}
.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.col-4 {
  flex: 0 0 33.33%;
  max-width: 33.33%;
}
.col-5 {
  flex: 0 0 41.66%;
  max-width: 41.66%;
}
.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.col-7 {
  flex: 0 0 58.33%;
  max-width: 58.33%;
}
.col-8 {
  flex: 0 0 66.66%;
  max-width: 66.66%;
}
.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}
.col-10 {
  flex: 0 0 83.33%;
  max-width: 83.33%;
}
.col-11 {
  flex: 0 0 91.66%;
  max-width: 91.66%;
}
.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
/* END GRID */

/* GRID */

@media screen and (max-width: 1280px) {
  .col-tab-1 {
    flex: 0 0 8.33%;
    max-width: 8.33%;
  }
  .col-tab-2 {
    flex: 0 0 16.66%;
    max-width: 16.66%;
  }
  .col-tab-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-tab-4 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .col-tab-5 {
    flex: 0 0 41.66%;
    max-width: 41.66%;
  }
  .col-tab-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-tab-7 {
    flex: 0 0 58.33%;
    max-width: 58.33%;
  }
  .col-tab-8 {
    flex: 0 0 66.66%;
    max-width: 66.66%;
  }
  .col-tab-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-tab-10 {
    flex: 0 0 83.33%;
    max-width: 83.33%;
  }
  .col-tab-11 {
    flex: 0 0 91.66%;
    max-width: 91.66%;
  }
  .col-tab-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .col-md-1 {
    flex: 0 0 8.33%;
    max-width: 8.33%;
  }
  .col-md-2 {
    flex: 0 0 16.66%;
    max-width: 16.66%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .col-md-5 {
    flex: 0 0 41.66%;
    max-width: 41.66%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33%;
    max-width: 58.33%;
  }
  .col-md-8 {
    flex: 0 0 66.66%;
    max-width: 66.66%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33%;
    max-width: 83.33%;
  }
  .col-md-11 {
    flex: 0 0 91.66%;
    max-width: 91.66%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
/* END GRID */

/* GRID */
@media screen and (max-width: 767px) {
  .col-sm-1 {
    flex: 0 0 8.33%;
    max-width: 8.33%;
  }
  .col-sm-2 {
    flex: 0 0 16.66%;
    max-width: 16.66%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .col-sm-5 {
    flex: 0 0 41.66%;
    max-width: 41.66%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33%;
    max-width: 58.33%;
  }
  .col-sm-8 {
    flex: 0 0 66.66%;
    max-width: 66.66%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33%;
    max-width: 83.33%;
  }
  .col-sm-11 {
    flex: 0 0 91.66%;
    max-width: 91.66%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
/*END GRID */

@media screen and (min-width: 1200px) {
  h1,
  .h1 {
    font-size: 2rem;
  }
  h2,
  .h2 {
    font-size: 2rem;
  }

  h3,
  .h3 {
    font-size: 1.75rem;
  }
  h4,
  .h4 {
    font-size: 1.5rem;
  }

  h5,
  .h5 {
    font-size: 1.25rem;
  }
}

/* User CSS */

.user-list-title {
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 30px;
}

.user-list-form-inner .row {
  gap: 30px;
}

.user-list-form-inner .row .MuiOutlinedInput-input {
  font-size: 18px;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #242641 !important;
}

.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #242641 !important;
}

.css-1e84g1j {
  width: 800px;
  padding: 60px 40px;
  border-radius: 20px;
}

.css-1e84g1j #transition-modal-title {
  margin-bottom: 30px;
  padding-bottom: 20px;
  text-align: center;
}

.user_edit_link {
  display: inline-block;
  margin: 0 5px;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .hide-in-mobile {
    display: none;
  }
}

.MuiBox-root {
  min-height: auto !important; 
}

.css-u9cm51 { 
  height: auto !important; 
}