.svg-funnel-js {
    font-family: Open Sans, sans-serif;
}

.svg-funnel-js .svg-funnel-js__container {
    width: 100%;
    height: 100%;
}

.svg-funnel-js .svg-funnel-js__labels {
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 0px;
    flex: 1 1 0;
    position: relative;
}

.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__value {
    font-size: 24px;
    /* color: #fff; */
    line-height: 18px;
    margin-bottom: 6px;
}

.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__title {
    font-size: 12px;
    font-weight: 700;
    /* color: #21ffa2; */
}

.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__percentage {
    font-size: 16px;
    font-weight: 700;
    /* color: #9896dc; */
}

.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__segment-percentages {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
    left: 0;
    padding: 8px 24px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: rgba(8, 7, 48, 0.8);
    margin-top: 24px;
    opacity: 0;
    -webkit-transition: opacity 0.1s ease;
    transition: opacity 0.1s ease;
    cursor: default;
}

.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__segment-percentages ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__segment-percentages ul li {
    font-size: 13px;
    line-height: 16px;
    color: #fff;
    margin: 18px 0;
}

.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__segment-percentages ul li .percentage__list-label {
    font-weight: 700;
}

.svg-funnel-js:not(.svg-funnel-js--vertical) {
    padding-top: 64px;
    padding-bottom: 16px;
}

.svg-funnel-js:not(.svg-funnel-js--vertical) .svg-funnel-js__label {
    padding-left: 24px;
}

.svg-funnel-js:not(.svg-funnel-js--vertical) .svg-funnel-js__label:not(:first-child) {
    border-left: 1px solid #9896dc;
}

.svg-funnel-js.svg-funnel-js--vertical {
    padding-left: 120px;
    padding-right: 16px;
}

.svg-funnel-js.svg-funnel-js--vertical .svg-funnel-js__label {
    padding-top: 24px;
}

.svg-funnel-js.svg-funnel-js--vertical .svg-funnel-js__label:not(:first-child) {
    border-top: 1px solid #9896dc;
}

.svg-funnel-js.svg-funnel-js--vertical .svg-funnel-js__label .label__segment-percentages {
    margin-top: 0;
    margin-left: 106px;
    width: calc(100% - 106px);
}

.svg-funnel-js.svg-funnel-js--vertical .svg-funnel-js__label .label__segment-percentages .segment-percentage__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
}

.svg-funnel-js .svg-funnel-js__subLabels {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 24px;
    position: absolute;
    width: 100%;
    left: 0;
}

.svg-funnel-js .svg-funnel-js__subLabels .svg-funnel-js__subLabel {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 12px;
    /* color: #fff; */
    line-height: 16px;
}

.svg-funnel-js .svg-funnel-js__subLabels .svg-funnel-js__subLabel:not(:first-child) {
    margin-left: 16px;
}

.svg-funnel-js .svg-funnel-js__subLabels .svg-funnel-js__subLabel .svg-funnel-js__subLabel--color {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin: 2px 8px 2px 0;
}


.svg-funnel-js {
    display: inline-block;
    position: relative;
}

.svg-funnel-js svg {
    display: block;
}

.svg-funnel-js .svg-funnel-js__labels {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.svg-funnel-js.svg-funnel-js--vertical .svg-funnel-js__labels {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}