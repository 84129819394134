/* Header */
.header_wrapper {
  padding: 28px 42px;
}

.right_header_part_title h1 {
  margin: 0;
}

.right_header_part_title h1 img {
  display: none;
}

.header-logo {
  text-align: center;
  margin-top: 40px;
}
.header-logo img {
  width: 35px;
}

.header_planing_box_icon {
  width: 50px;
  display: none;
}

/* HEADER NAV */

.bt-menu {
 
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 0;
}

.bt-menu.bt-menu-open {
	height: 100%;

}

.bt-menu-trigger {
  display: none;
	position: relative;
	width: 22px;
	height: 17px;
	cursor: pointer;
	margin-right: 10px;
}

.bt-menu-trigger span {
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 100%;
	height: 2px;
	margin-top: 8px;
	background-color: #000;
	font-size: 0px;
	transition: background-color 0.3s;
}

.bt-menu-open .bt-menu-trigger span {
	background-color: transparent;
}

.bt-menu-trigger span:before,
.bt-menu-trigger span:after {
	position: absolute;
	left: 0;
	width: 100%;
	height: 100%;
	background: #000;
	content: '';
	transition: transform 0.3s;
}

.bt-menu-trigger span:before {
	transform: translateY(-250%);
}

.bt-menu-trigger span:after {
	transform: translateY(250%);
}

.bt-menu-open .bt-menu-trigger span:before {
	transform: translateY(0) rotate(45deg);
}

.bt-menu-open .bt-menu-trigger span:after {
	transform: translateY(0) rotate(-45deg);
}

.menu_overly {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999; 
  display:none;
  -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    -webkit-tap-highlight-color: transparent;
}

.bt-menu-open .bt-menu-trigger {
  z-index: 9999;
} 

.bt-menu-open .bt-menu-trigger span:before, .bt-menu-open .bt-menu-trigger span:after {  background: #fff; }

.header-nav {
  margin-top: 30px;
  padding: 20px;
  background: #f8f8f8;
  border-radius: 40px;
  max-width: 1080px;
}

.header-nav ul {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
}

.header-nav ul a {
  padding: 10px;
  text-decoration: none;
  display: inline-block;
  font-size: 19px;
  color: #242641;
  font-weight: 600;
  border-bottom: 2px solid transparent;
}
.header-nav ul a img {
  width: 40px;
  margin-bottom: -7px;
  margin-right: 10px;
}
.header-nav ul a:hover {
  color: #484b75;
  border-color: #484b75;
}

/* END HEADER NAV */

.DownloadReportAndFilter button {
  font-size: 18px;
  text-transform: capitalize;
  border: 0 !important;
  font-weight: 700;
}

.DownloadReportAndFilter button .MuiButton-icon {
  border-right: 0.1rem solid rgba(138, 143, 160, 0.2);
  padding-right: 1.5rem;
  margin-right: 1.5rem;
}

.DownloadReportAndFilter button:hover {
  border-color: #f5f4f6;
}

.right_header_part { 
  display: flex;
  flex-wrap: wrap;
  row-gap: 50px;
}

.right_header_part_title h1 {
  font-weight: 900;
  margin-bottom: 10px;
}

.right_header_part_date {
  display: flex;
  flex-wrap: wrap;
}

.right_header_part_title {
  padding-right: 20px;
}
 
.header_planing_box {
  display: flex; 
}

.header_date_timebox,
.header_planing_box {
  padding-left: 20px;
  padding-right: 20px;
}

.header_date_timebox {
  display: flex;
  flex-wrap: wrap;
  width: 220px;
  align-items: center;
}

.header_date_timebox_left {
  width: 57px;
  padding-right: 10px;
}
.header_date_timebox_left svg {
  width: 32px;
  height: auto;
}

.header_date_timebox_right {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 60px);
  line-height: 1;
}

.last_update_time {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-top: 6px;
  font-weight: 500;
}

.header_date_timebox_right strong,
.header_date_timebox_right span,
.header_planing_item_text strong,
.header_planing_item_text span {
  width: 100%;
  line-height: 1.4;
}
.header_planing_item:last-child {
  margin-right: 0;
}
 

.header_date_timebox_left .anticon {
  width: 37px;
}

.dark-mode .bt-menu-trigger span, .dark-mode  .bt-menu-trigger span:before, .dark-mode  .bt-menu-trigger span:after {
  background: #fff;
}

.dark-mode.bt-menu-open .bt-menu-trigger span {
  background: transparent;
} 

.header_planing_item {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  overflow: hidden;
  margin-right: 20px;
  border-radius: 11px;
  overflow: hidden;
  transition: 400ms;
  -webkit-transition: 400ms;
  /* background: #fff; */
  max-width: 170px;
  /* box-shadow: 0 0 13px #ccc; */
}

.header_planing_add {
  padding: 10px 10px 10px 10px;
  border: 1px dashed #ccc;
  border-radius: 10px;
  display: flex;
  align-items: center;
  height: 100%;
  width: 70px;
  justify-content: center;
}

.header_planing_item_icon {
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 11px;
  height: 100%;
  padding: 0px 10px 0 10px;
}

.header_planing_item_icon img {
  width: 39px;
}

.header_planing_item_text {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: calc(100% - 60px);
  line-height: 1;
  padding: 10px 9px;
  height: 100%;
}

.header_planing_item_text span.planning_item_strong,
.header_date_timebox_right strong {
  font-size: 15px !important;
  font-weight: 700 !important;
}

.header_planing_item_text span,
.header_date_timebox_right span {
  font-size: 14px;
}

.header_planing_item_text .planning_item_strong {
  line-height: 1.4;
}
.header_planing_item_text span {
  line-height: 1.4;
}

.header_planing_add span.anticon {
  font-size: 26px;
}

.header_planing_add svg {
  margin: 0;
}

.header_planing_add svg path {
  fill: #b1b1b1;
}
.header_planing_item .header_planing_item_text span {
  font-size: 22px;
  font-weight: 600;
}
.header_date_timebox,
.right_header_part_title {
  border-right: 1px solid #ccc;
}

.header_right_bar .DownloadReportAndFilter {
  display: flex;
  flex-wrap: wrap;
}

.react-calendar {
  z-index: 999;
}

.header_right_bar .DownloadReportAndFilter > div {
  margin-right: 10px !important;
}
.user_dropdown .settingOptions {
  z-index: 999;
}

.header-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 50px;
}

.notification-alert,
.user_dropdown {
  padding-left: 15px;
  margin-left: 30px;
}

.header_right_bar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.user_dropdown button {
  padding: 0;
  display: inline-block;
  background: #70a6f7;
  border-radius: 50%;
  width: 40px;
}

.user_dropdown button:hover {
  background: #70a6f7;
}

.MuiPaper-root li {
  width: 100%;
  height: auto;
  padding: 1.5rem;
  display: flex;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.2;
}
.MuiPaper-root li:not(:last-child) {
  border-bottom: 0.1rem solid rgba(128, 128, 128, 0.1);
}

.MuiPaper-elevation {
  border-radius: 10px;
}

.MuiMenu-list li {
  font-size: 13px !important;
  padding: 14px 30px;
}
/* End Header */

/* Side Bar */
.sidebarWrapper_main_nav ul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  justify-content: center;
  list-style: none;
}

.sidebarWrapper_main_nav ul li img {
  width: 55px;
}

.sidebarWrapper_main_nav {
  margin-top: 40px;
}

.Onoffmode {
  margin-top: 30px;
  padding-left: 10px;
}

.Onoffmode span {
  width: 58px;
  height: 58px;
  display: inline-block;
  padding: 17px;
  border-radius: 50%;
}
.Onoffmode span img {
  cursor: pointer;
}

.Onoffmode span.active {
  background: #f7f7f7;
  box-shadow: 0 0 23px #ccc;
}
.onoffmode_ul {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.sidebarWrapper_main_nav ul li span {
  width: 100%;
  font-weight: 600;
  font-size: 13px;
  padding-top: 6px;
  display: inline-block;
}

.side-bar {
  width: 100%;
}

.sidebarWrapper_main_nav ul li {
  text-align: center;
}

.toggle_sidebar_menu {
  position: absolute;
  right: -36px;
  background: #ffffff;
  top: 196px;
  line-height: 0;
  padding: 3px;
  border-radius: 50%;
  z-index: 999;
  cursor: pointer;
}

.toggle_sidebar_menu svg {
  width: 26px;
  height: 26px;
  display: inline-block;
  fill: #637381;
}

/* END Side Bar */
.css-1nvnyqx-MuiPaper-root-MuiDrawer-paper.MuiDrawer-paperAnchorTop {
  background-color: rgb(255 255 255 / 90%);
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}
.MuiDrawer-paperAnchorTop {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.top-popup-list h5 {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
  position: relative;
}

.top-popup-list h5 .top-popup-list-toggle {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  display: none;
}

.top-popup-list {
  width: 100%;
  padding-bottom: 10px;
  max-width: 300px;
}
.top-popup {
  padding: 30px;
}
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root svg {
  width: 24px;
  height: 24px;
}
.top-popup-main-title {
  padding: 20px;
  font-weight: 600;
  font-size: 20px;
}
.MuiDrawer-paperAnchorTop .MuiBox-root.css-agnjr6 {
  margin: 0;
}
.MuiDrawer-paperAnchorTop .css-h4y409-MuiList-root {
  padding: 0;
}
.top-popup-main-title svg {
  margin-bottom: -4px;
  margin-right: 6px;
  width: 26px;
  height: 26px;
}
.model-filter-labels {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 10px;
}

.model-filter-labels label {
  background: #242641;
  color: #fff;
  font-weight: 500;
  padding: 7px 12px;
  font-size: 13px;
  border-radius: 14px;
  display: inline-flex;
  align-items: center;
}

span.model-filter-labels-close {
  display: inline-block;
  padding-left: 4px;
  cursor: pointer;
  margin-bottom: -4px;
}

span.model-filter-labels-close svg {
  margin-right: 0;
  width: 16px;
  height: 16px;
}

.model-filter-buttons {
  gap: 16px;
}

.model-filter-title {
  margin-top: 8px;
}

.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  backdrop-filter: blur(5px);
}

.model-filter {
  padding: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -30px -30px -30px;
}

.model-filter row {
  width: 100%;
}
.model-filter .row {
  width: 100%;
}
/* End Side Bar */

/* Calander */
.DownloadReport .MuiButton-outlined {
  position: relative;
}

.DownloadReport .MuiButton-outlined .MuiTouchRipple-root {
  position: absolute;
}

.DownloadlastMontht .menu_calander {
  position: absolute;
  left: -220px;
  /* box-shadow: 0 0 1rem 0 #dfdfdf; */
  border-radius: 16px;
  overflow: hidden;
  padding: 10px;
  z-index: 99999;
  /* background: #fff; */
}

.DownloadlastMontht {
  position: relative;
}

.DownloadlastMontht .menu_calander .MuiChip-label,
.DownloadlastMontht .menu_calander .MuiTypography-root,
.DownloadlastMontht .MuiDateRangePickerDay-day,
.css-dplwbx-MuiPickersCalendarHeader-label {
  font-size: 14px;
}

.rdrStaticRangeLabel {
  display: block;
  outline: 0;
  line-height: 18px;
  text-align: left;
}

.DownloadlastMontht .menu_calander li {
  padding: 0;
  border-bottom: 0.1px solid rgba(128, 128, 128, 0.1);
}

.DownloadlastMontht .menu_calander li .MuiButtonBase-root {
  background: transparent;
  width: 100%;
  height: auto;
}

.DownloadlastMontht .menu_calander li .MuiButtonBase-root span {
  text-align: left !important;
  display: inline-block;
  width: 100%;
  line-height: 18px;
  padding: 12px 20px;
}

.DownloadlastMontht .menu_calander .MuiPickersLayout-toolbar {
  padding: 0;
}

.DownloadlastMontht
  .menu_calander
  .MuiPickersLayout-toolbar
  .MuiTypography-overline {
  display: none;
}

.DownloadlastMontht
  .menu_calander
  .MuiPickersLayout-toolbar
  .MuiDateRangePickerToolbar-container {
  width: 100%;
  justify-content: space-around;
  /* background-color: rgb(239, 242, 247); */
  padding: 0.833em;
  align-items: center;
}

.DownloadlastMontht .MuiButton-text {
  border-radius: 4px;
  /* background-color: rgb(255, 255, 255); */
  box-shadow: 0 1px 2px 0 rgba(35, 57, 66, 0.21);
  border: 1px solid transparent !important;
  cursor: pointer;
  height: 32px;
  line-height: 2.5em;
  border: 0px;
  width: 130px;
  /* color: #849095; */
}

.DownloadlastMontht .MuiButton-text:hover,
.DownloadlastMontht .MuiButton-text:focus {
  border-color: rgb(61, 145, 255) !important;
}

.DownloadlastMontht .css-22qels-MuiList-root {
  margin-top: -66px !important;
}

.menu_calander .MuiPickersLayout-actionBar {
  display: none;
}

.menu_calander {
  display: none;
}
.DownloadlastMontht:hover .menu_calander {
  display: block;
}

/* END Calender */
/* TOP BAR */

.MuiDrawer-paperAnchorTop {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
/* END TOP BAR */

@media screen and (max-width: 1300px) {
  .right_header_part .right_header_part_date {
    width: 100%;
  }

  .right_header_part_title {
    border: 0;
    margin-bottom: 30px;
    padding: 0;
  }

  .header-inner,
  .right_header_part {
    row-gap: 0;
  }

  .header_right_bar {
    justify-content: end;
    margin-top: -270px;
    align-items: baseline;
    width: 100%;
  }

  .header-inner {
    align-items: center;
    width: 100%;
  }
}

@media screen and (max-width: 1400px) {
  .header_date_timebox,
  .header_planing_box {
    padding-left: 10px;
    padding-right: 10px;
  }
  .right_header_part_title h1 {
    font-size: 1.5rem;
  }
  .DownloadReportAndFilter button {
    font-size: 16px;
  }

  .header_planing_item_text strong {
    font-size: 13px;
  }
  .header_planing_item_icon img {
    width: 28px;
  }
  .header_planing_item {
    max-width: 170px;
    width: 170px;
  }
  .header_date_timebox {
    margin-right: 20px;
  }
  .DownloadReportAndFilter button .MuiButton-icon {
    padding-right: 10px;
    margin-right: 10px;
  }
  .notification-alert,
  .user_dropdown {
    padding-left: 1px;
    margin-left: 0px;
  }
  .right_header_part_title {
    /* width: 20%; */
    max-width: 162px;
  }
}

@media screen and (max-width: 1024px) {
  .sidebarWrapper_main_nav ul li img {
    width: 33px;
  }

  .sidebarWrapper_main_nav ul {
    gap: 20px;
  }

  .Onoffmode span {
    width: 38px;
    height: 38px;
    padding: 10px;
  }

  .onoffmode_ul {
    justify-content: center;
  }

  .model-filter {
    margin: 0 -20px -20px -20px;
  }
  .header_right_bar {
    margin-top: -280px;
  }
  .header_date_timebox {
    width: 180px;
    margin: 0;
    border: 0;
  }

  .header_planing_box {
    width: 100%;
    justify-content: space-between; 
    margin-top: 40px;
  }

  .model-filter-title {
    text-align: center;
  }

  .model-filter-buttons {
    justify-content: center;
  }

  .top-popup-list h5 {
    font-size: 16px;
    margin-bottom: 12px;
    padding-bottom: 12px;
  }

  .top-popup {
    padding: 20px;
  }

  .model-filter-labels {
    margin: 20px 0;
    justify-content: center;
}

}

@media screen and (max-width: 1600px) {
  .DownloadlastMontht .menu_calander {
    transform: translateX(-10%);
  }
}

.mobile-size-header{
  display: none !important;
}
.user-menu-container{
  display: block;
}

@media screen and (max-width: 767px) { 
  .header_planing_box_icon {
    display: inline-block;
}
  .header_date_timebox_right span, .header_date_timebox_right strong, .last_update_time {
    font-size: 13px !important;
    line-height: 1.5;
  }

  .mobile-size-header{
    display: flex !important;
    margin-top: -30px;
  }
  .header-title-container,.user-menu-container{
   display: none;
  }
  
  .bt-menu-trigger {display: inline-block;}
  .bt-menu-open .menu_overly {display:block;}
  .right_header_part_title {
    max-width: 100%;
    margin-bottom:0px;
  }
  .right_header_part_title span {
    display: inline-block;
    margin-left: 57px;
  }
  .right_header_part_title h1 img {
    display: inline-block;
    position: relative;
    bottom: -20px;
    margin-right: 18px;
    width: 36px;
  } 

.top-popup-list span {
    font-size: 12px;
}

.top-popup-list h5 .top-popup-list-toggle {display: inline-block;}

.top-popup-list h5 {
  font-size: 12px;
  margin-bottom: 8px;
  padding-bottom: 8px;
}

.top-popup-list span {
  font-size: 12px;
}

.top-popup-main-title {
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.top-popup-main-title svg {
  margin-bottom: -3px;
  margin-right: 4px;
  width: 16px;
  height: 16px;
}

.model-filter-title h5 {
  font-size: 13px;
}

.model-filter {
  padding: 20px 10px;
}

.top-popup-inner {
height: calc(100vh - 140px);
margin-bottom: 20px;
overflow-y: scroll;
overflow-x: hidden;
display: flex;
flex-wrap: wrap;
width: 100%;
}

.model-filter-buttons {
  gap: 8px;
}

.top-popup-list {
  padding-bottom: 30px;
  max-width: 100%;
}

  .right_header_part_title h1 {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 600;
  }

  .DownloadlastMontht .menu_calander {
    left: 0;
    padding: 0;
    transform: translateX(0%);
}

.DownloadlastMontht .css-22qels-MuiList-root {
    display: none;
}

  .right_header_part_title span {
    font-size: 18px;
    font-weight: 600;
  }

  .header_right_bar {
    margin-top: 0;
    order: -1;
  }

  .right_header_part {
    width: 100%;
    margin-top: -10px;
  } 
 
  .header_right_bar .DownloadReportAndFilter {
    order: 1;
    width: 100%;
    justify-content: end;
    margin-top: 20px; 
    justify-content: center;
  } 
/* 
  .header_right_bar .DownloadReportAndFilter .nowrap {width: calc(100% - 35px); text-align: left;} */

  .right_header_part_date {
    margin-top: 30px;
}
 

  .header_date_timebox {
    width: 100%;
    align-items: center;
     background: #f9f9f9; 
    border-radius: 10px;
    padding: 5px;
    justify-content: space-between;
  }
  .dark-mode .header_date_timebox {background: #303136;}

  .header_date_timebox_right {
    width: calc(100% - 197px);
  }

  .last_update_time { 
    width: 132px; 
  }
  
  .header_date_timebox_left {
    width: 55px;
    line-height: 1; 
    padding-right : 5px;
  }

  .header_date_timebox_left svg {
    width: 20px;
  }

  .header_date_timebox_right strong,
  .header_date_timebox_right span {
    width: auto; 
  }

  .header_date_timebox_right span {
    margin-left: 5px;
    font-weight: 700;
  }

  .header_wrapper { padding-left: 0; padding-right: 0; }

  
  .DownloadReportAndFilter button { 
      padding: 6px 14px !important; 
  } 

  .DownloadReportAndFilter button .MuiButton-icon { 
    padding-right: 0;
    margin-right: 10px;
    border: 0;
  }

  .header_right_bar .DownloadReportAndFilter > div {
    margin-right: 0 !important;
    width: 50%;
  }
  .header_right_bar .DownloadReportAndFilter > div button {
    width: 100%;
}
 
  .header_right_bar .DownloadReportAndFilter > div:nth-child(2) {
    padding-right: 0;
  }

  .header_right_bar .DownloadReportAndFilter > div:nth-child(2):after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 1px;
    background: url( 'data: image/svg+xml,<svg width="1" height="38" viewBox="0 0 1 38" fill="none" xmlns="http://www.w3.org/2000/svg"><line x1="0.5" y1="2.18557e-08" x2="0.499998" y2="38" stroke="url(%23paint0_linear_28_499)"/><defs><linearGradient id="paint0_linear_28_499" x1="1" y1="1" x2="3.50403" y2="1.23868" gradientUnits="userSpaceOnUse"><stop stop-color="%23903AD9" stop-opacity="0"/><stop offset="0.218954" stop-color="%23903AD9"/><stop offset="0.538084" stop-color="%234170E5"/><stop offset="0.754195" stop-color="%2311BCC6"/><stop offset="1" stop-color="%2311BCC6" stop-opacity="0"/></linearGradient></defs></svg>');     
}


  .header_planing_item {
    background: transparent;
    box-shadow: 0 0;
  }

  .header_planing_item .header_planing_item_icon {
    display: none;
  }

  .header_planing_item .header_planing_item_text {
    width: 100%;
  }

  .header_planing_item .header_planing_item_text {
    padding: 0;
  }

  .header_planing_item {
    margin-right: 10px;
  }

  .header_planing_item {
    width: inherit;
    text-align: center;
  }

  .header_planing_item_icon {
    width: 30px;
    padding: 0;
  }

  .header_planing_box {
    margin-top: 20px;
    align-items: center;
  }



  .page-layout-right {
    padding-left: 20px;
  }

  .header_planing_item_text span.planning_item_strong { 
    margin-bottom: 4px;
  } 
  .header_planing_item .header_planing_item_text span {font-size: 16px;}


.css-lnfzc8-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day, .DownloadlastMontht .menu_calander .MuiChip-label, .DownloadlastMontht .menu_calander .MuiTypography-root, .DownloadlastMontht .MuiDateRangePickerDay-day, .css-dplwbx-MuiPickersCalendarHeader-label {
  font-size: 11px !important;
} 
}
 


@media screen and (max-width: 380px) {
  .DownloadReportAndFilter button .MuiButton-icon {
    width: 32px; }
  .header_date_timebox_left {width: 43px;}
  .DownloadReportAndFilter button {font-size: 10px;}
  .DownloadReportAndFilter button { 
    padding: 6px 10px !important;
  }
  .header_date_timebox_right span, .header_date_timebox_right strong, .last_update_time {
    font-size: 9px !important;
  }
  .header_date_timebox_right {
    width: calc(100% - 169px);
  }
  .header_date_timebox {padding: 5px 15px;}
  .header_date_timebox_left {width: 28px;padding:}
  .last_update_time {
    font-size: 9px;
    width: 120px;
  }
 
  .header_planing_item_text span.planning_item_strong {
    font-size: 11px !important; }

    .right_header_part_date {
      margin-top: 40px;
    }
    .right_header_part_title span {font-size: 12px;}
    .right_header_part {
      margin-top: -30px;
    }
    .header_right_bar .DownloadReportAndFilter {   margin-top: 14px}
   
   
}