.device .device-frame {
  & img {
    max-width: 100%;
    -webkit-font-smoothing: antialiased;
    backface-visibility: hidden;
    transform: translateZ(0);
    -webkit-font-smoothing: subpixel-antialiased;
  }
}

[data-baseweb="drawer"] {
  z-index: 99999;
}

.accordion-panel-content {
  >p {
    &:first-child {
      margin-top: 0px;
    }

    &:last-child {
      margin-bottom: 0px;
    }
  }
}

.device .slick-slider {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px !important;
  mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
  -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);

  .slick-list {
    width: 100%;
    flex-grow: 1;
  }

  .slick-track>div {
    display: inline-block !important;
  }

  .slick-slide div {
    outline: 0 !important;
  }

  .slick-arrow {
    display: none !important;
  }
}

.device-iphone-x {
  height: 50rem;
  width: 428px;
  position: relative;
  transform: scale(0.8);
  transform-origin: top;
}

.device-iphone-x .device-frame {
  background: black;
  border-radius: 68px;
  box-shadow: inset 0 0 2px 2px #c8cacb, inset 0 0 0 1px #e2e3e4;
  height: 868px;
  background: 'linear-gradient(62.72deg, #903AD9 6.2%, #4170E5 46.41%, #11BCC6 76.75%)';
  padding: 28px;
  width: 428px;
  box-sizing: border-box;
  position: relative;
}

.device-iphone-x .device-stripe::after,
.device-iphone-x .device-stripe::before {
  border: solid rgba(51, 51, 51, 0.25);
  border-width: 0 7px;
  content: "";
  height: 7px;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 9;
}

.device-iphone-x .device-stripe::after {
  top: 85px;
}

.device-iphone-x .device-stripe::before {
  bottom: 85px;
}

.device-iphone-x .device-header {
  background: black;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  height: 30px;
  left: 50%;
  margin-left: -102px;
  position: absolute;
  top: 28px;
  width: 204px;
}

.device-iphone-x .device-header::after,
.device-iphone-x .device-header::before {
  content: "";
  height: 10px;
  position: absolute;
  top: 0;
  width: 10px;
}

.device-iphone-x .device-header::after {
  background: radial-gradient(circle at bottom left, transparent 0, transparent 75%, #222 75%, #222 100%);
  left: -10px;
}

.device-iphone-x .device-header::before {
  background: radial-gradient(circle at bottom right, transparent 0, transparent 75%, #222 75%, #222 100%);
  right: -10px;
}

.device-iphone-x .device-sensors::after,
.device-iphone-x .device-sensors::before {
  content: "";
  position: absolute;
}

.device-iphone-x .device-sensors::after {
  background: #444;
  border-radius: 3px;
  height: 6px;
  left: 50%;
  margin-left: -25px;
  top: 32px;
  width: 50px;
}

.device-iphone-x .device-sensors::before {
  background: #444;
  border-radius: 50%;
  height: 14px;
  left: 50%;
  margin-left: 40px;
  top: 28px;
  width: 14px;
}

.device-iphone-x .device-btns {
  background: #c8cacb;
  height: 32px;
  left: -3px;
  position: absolute;
  top: 115px;
  width: 3px;
}

.device-iphone-x .device-btns::after,
.device-iphone-x .device-btns::before {
  background: #c8cacb;
  content: "";
  height: 62px;
  left: 0;
  position: absolute;
  width: 3px;
}

.device-iphone-x .device-btns::after {
  top: 60px;
}

.device-iphone-x .device-btns::before {
  top: 140px;
}

.device-iphone-x .device-power {
  background: #c8cacb;
  height: 100px;
  position: absolute;
  right: -3px;
  top: 200px;
  width: 3px;
}

.device-iphone-x .device-content {
  border-radius: 40px;
  height: 812px;
  width: 375px;
  position: relative;
  overflow: hidden;
}

.device-iphone-x .device-content img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 40px;
}